/** Default Chrome CSS */
.docked-toolbar-wrapper {
  flex: 0 1;
}

.hotspot5 {
  position: absolute;
  left: 115px;
}

/** Edge specific CSS */
@supports (-ms-ime-align: auto) {
  .hotspot5 {
    position: absolute;
    left: 115px;
  }
}

/** Safari specific CSS */
@supports (-webkit-hyphens: none) {
  .hotspot5 {
    position: absolute;
    left: 50px;
  }
}

.Preferences {
  position: absolute;
  top: 46px;

  &.iconLabelsDisabled {
    top: 26px;
  }
}
