.error-modal {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(
      131.3deg,
      rgb(85, 178, 135, 0.9) -7.33%,
      rgb(32, 88, 118, 0.9) 61.91%
    ),
    url(../../resources/WSW_login_background.png);
  background-color: #212f3e;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
