/* Signin Styles */
/* TODO: Multiple Form classes are found. Need to check and merge into one or remove. */
.Form {
  width: 34em;
}

.sign-in-wrapper-small-margin {
  margin-top: 4em !important;
}

.sign-in-wrapper {
  margin-top: 8em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Button-form {
  max-width: 420px !important;
  --awsui-color-background-button-normal-default: #3891ff;
  --awsui-color-background-button-normal-hover: #5D88FF;
  display: block;
}

.Button-form span {
  color: #F2F3F3;
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
}


.sign-in-to-workspacesweb {
  color: #414750;
  font-family: "Amazon Ember";
  font-size: 16px;
  line-height: 20px;
}

.login-product-description {
  color: #414750;
  font-family: "Amazon Ember";
  font-size: 14px;
  line-height: 17px;
}

.login-learn-more {
  color: #5D88FF;
  font-family: "Amazon Ember";
  font-size: 14px;
  line-height: 17px;
}

.awsui-util-help-panel {
  background-color: white;
  border: solid 1ps lightgray;
  box-shadow: 0 0 10px lightgray;
  width: 430px;
  top: 45%;
  left: 50%;
}

.awsui .awsui-util-help-panel > :last-child {
  margin-bottom: 5rem !important;
}
.awsui-util-status-negative{
  type: "warning";
}
.awsui-util-container-header {
  text-align: center;
  padding: 8%;
}
.awsui-util-container-header p {
  font-size: large !important;
}

.awsui-util-container-header img {
  width: 250px;
}

.awsui-util-container-header .form-title{
  font-size: 3em;
}

.awsui-util-container-header Button {
  width: 257px;
  height: 39px;
}

.awsui-util-alert-ontop {
  width: calc(430px + 6rem);
}