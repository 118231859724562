.branded-horizontal-split-layout-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.euc-container {
  height: 100vh;
  width: 100vw;
}

.euc-main-container-style {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.euc-sign-in-parent-container-style {
  position: relative;
  width: 100%;
  height: 100%;
}

.euc-sign-in-container-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.euc-sign-in-container-child-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.euc-btn-container-style {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.branded-horizontal-split-layout-left-panel {
  box-shadow: none !important;
}

.euc-sign-in-header {
  width: 270px;
}

.euc-incomplete-header {
  width: 328px;
}

.branded-horizontal-split-layout-mobile-brand-logo {
  display: none;
}

.euc-sign-in-parent-container-mobile-style {
  width: 100%;
  height: 100%;
}
.euc-sign-in-container-mobile-brand-logo-style {
  height: 36px;
  align-self: center;
  margin-bottom: 2rem;
}
