/*
 * Since we fix the height of the hidden appstream-container
 * element, we need to hide scroll.
 */
.embed-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.appstream-container-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.appstream-container {
    flex: 1 1;
    max-height: -webkit-fill-available;
    overflow: hidden;
}