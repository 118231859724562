footer {
    padding: 0 20px;
    font-family: Amazon Ember, Arial,Droid Sans,sans-serif;
    text-align: left;
    font-size: 12px;
    line-height: 30px;
    position: relative;
}

#terms-of-use, #privacy, #shortbread-footer-link {
    padding: 0 2em 0 2em;
    font-weight: bold;
    /* color: #44b9d6;  For dark mode*/
    color: #ffffff;
    font-size: 12px;
    padding: 0 1em;
}

#copyright-notice {
    color: #95a5a6;
    border-left: 0;
    display: inline;
    padding: 0 1em;
}

#page-footer {
    margin-top: auto;
    background-color: #232f3e;
    padding: 10px;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
}

#page-footer a {
    min-width: 50px;
    text-decoration: none;
    cursor: pointer;
} 

#version {
    color: #44b9d6;
}

.footer-left {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    float: left;
}

.footer-right a {
    margin-right: 20px;
    color: #aab7b8;
    float: right;
}

.footer-pipe {
    color: #879596
}
