/* Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved. */
@import url("~@amzn/awsui-components-react/index.css");
@import url("./Signin.css");

html {
  width: 100%;
  height: 100%;
}

body {
  /* Need to set body width and height for toolbar */
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-background-style {
  background-color: #fafafa;
  background-image: url("../resources/background.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.euc-body-background-style {
  background-color: #212f3e;
  background-image: linear-gradient(
      131.3deg,
      rgb(85, 178, 135, 0.9) -7.33%,
      rgb(32, 88, 118, 0.9) 61.91%
    ),
    url(../resources/WSW_login_background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

#root {
  height: 100vh;
}

/* Common - Styles */
.Right-border {
  border-right: 1px solid gray;
}

/* Common - Flex Styles */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.col-grow-1 {
  flex-grow: 1 !important;
}

.col-grow-2 {
  flex-grow: 2 !important;
}

.col-grow-3 {
  flex-grow: 3 !important;
}

/* Unused */
/* TODO: Cleanup after checking the need */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Form {
  left: 50%;
  top: 55%;
  margin-left: -25%;
  position: absolute;
  margin-top: 10%;
  padding: 1% 10% 10% 10%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.Text-form {
  color: #747487;
  font-size: 16px;
  padding-top: 10%;
}

.On-top {
  z-index: 2147483647 !important;
}

.On-bottom {
  z-index: -2147483647 !important;
}

.Hide-visibility {
  visibility: hidden;
}
